import FavorateButton from "../favorates/Button";
import { useEffect } from "react";
import { Button, Dialog, DialogContent, DialogActions, Grid, Box, Typography } from "@mui/material";
import { Link } from "@mui/icons-material";
import useEventStore from "../../stores/eventStore";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function EventDetailModal() {
  const isModalOpen = useEventStore(s => s.isModalOpen)
  const currentItem = useEventStore(s => s.currentItem)
  const convert = useEventStore(s => s.convert)
  const setIsModalOpen = useEventStore(s => s.setIsModalOpen)

  useEffect(() => {
    if (isModalOpen) {
      reload()
    }
  }, [currentItem.act_id, isModalOpen])

  const reload = () => {
    if (currentItem.act_id) {
      convert({
        failed: () => { }
      })
    }
  }

  function Links() {
    function LinkButton({ text, url, qr }) {
      const image = qr ? <img src={qr} alt={url}
        style={{ maxHeight: 150, maxWidth: 150 }}
      ></img> : null
      return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <a target='_blank' rel="noreferrer" href={url}>
            <Button style={{ width: 100 }}>
              <Link /> {text}
            </Button>
          </a>
        </div>
        <div>
          {image}
        </div>
      </Box>
      )
    }

    const link = currentItem.url ?
      <LinkButton text='打开链接' url={currentItem.url} qr={currentItem.qr} /> :
      null
    const deepLink = currentItem.deeplinkUrl ?
      <LinkButton text='打开APP' url={currentItem.deeplinkUrl} qr={currentItem.deeplink_qr} /> :
      null
    return (
      <Box display='flex'>
        {link}
        {deepLink}
      </Box>
    )
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        scroll="paper"
        maxWidth='lg'
      >
        <DialogContent sx={{ pb: 0 }}>
          <Grid container
            columns={{ xs: 6, sm: 6, md: 12 }}
          >
            <Grid item xs={6}>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {
                  currentItem?.pics?.map((x, i) => <SwiperSlide key={i}><div
                    key={x}
                    style={{
                      background: '#364d79',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <img
                      style={{ maxHeight: '50vh', maxWidth: '75vw' }}
                      src={x} alt={x} />
                  </div></SwiperSlide>)
                }
              </Swiper>
            </Grid>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={6} md={5}>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {currentItem.act_name}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                  佣金: {currentItem.commission_rate}
                </Typography>
              </Box>
              <Links></Links>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FavorateButton style={{ width: 50 }} item={currentItem}></FavorateButton>
          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}