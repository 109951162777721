import { create } from 'zustand'
import axios from '../axios'
import { getItemKey } from '../components/util'
import QRcode from 'qrcode'
import { persist } from 'zustand/middleware'
import { platformEnum } from "../config";
import { immer } from 'zustand/middleware/immer'

function getUniqueValues(arr) {
  var values = arr.flat()
  var set = new Set(values)
  return Array.from(set)
}

const useItemStore = create(
  persist(
    immer(
      (set, get) => ({
        //search params
        sort: "1",
        minPrice: 0,
        salesGt0: false,
        sources: ['taobao', 'jd', 'pdd'],
        keyword: '',
        pageSize: 50,
        setSort: (value) => set({ sort: value }),
        setMinPrice: (value) => set(() => {
          if (value) {
            return { minPrice: parseFloat(value) }
          }
          return { minPrice: 0 }
        }),
        setSalesGt0: (value) => set({ salesGt0: value }),
        setPageSize: (value) => set({ pageSize: value }),
        setSources: (value) => set({ sources: value }),
        setKeyword: (value) => set({ keyword: value }),

        //keyword history
        keywordHistory: [],
        isHistoryDialogOpen: false,
        setHistoryDialogOpen: (value) => set({ isHistoryDialogOpen: value }),
        clearKeywordHistory: () => set({ keywordHistory: [] }),
        addKeywordHistory: () => set(state => {
          const { keywordHistory, keyword } = state
          return { keywordHistory: Array.from(new Set([keyword, ...keywordHistory])) }
        }),
        removeKeywordHistory: (value) => set(state => {
          const { keywordHistory } = state
          return { keywordHistory: keywordHistory.filter(x => x !== value) }
        }),

        //qrcode
        qrcode: '',
        setQrcode: (value) => set({ qrcode: value }),
        generateQrcode: async (value) => {
          const { currentItem } = get()
          const { goodsId, source } = currentItem
          const itemKey = getItemKey(currentItem)
          const qrcode = await QRcode.toDataURL(`https://www.haodanku.com/item?id=${goodsId}&pid=${platformEnum[source].pid}&activityId=${platformEnum[source].activityId}&backUrl=${encodeURIComponent(`https://www.haodanku.com/item?id=${goodsId}&pid=${platformEnum[source].pid}&activityId=${platformEnum[source].activityId}`)}`)
          set({ qrcode })
        },

        //component status
        loading: false,
        isModalOpen: false,
        setLoading: (value) => set({ loading: value }),
        setIsModalOpen: (value) => set({ isModalOpen: value }),

        //data
        currentItem: {},
        itemList: [],
        knownItems: {},
        setCurrentItem: (value) => set({ currentItem: value }),
        clear: () => set({ itemList: [] }),

        //requests
        search: async () => {
          const { clear, sources, keyword, pageSize, sort, addKeywordHistory } = get()
          clear()
          set({ loading: true })
          addKeywordHistory()
          async function req(source) {
            const { maxPageSize } = platformEnum[source]
            return axios.post('/search', {
              pageSize: Math.min(maxPageSize, pageSize),
              keyword, source, sort
            }, { timeout: 10000 })
              .then(res => {
                const { data } = res.data
                data.forEach(x => {
                  if (x.price && x.commission) {
                    x.finalPrice = x.price - x.commission * 0.85
                  } else if (x.price) {
                    x.finalPrice = x.price
                  }
                })
                if (data && Array.isArray(data) && data.length > 0) {
                  set({ itemList: [...get().itemList, ...data] })
                }
                return data
              })
              .catch(e => {
                console.log(e)
              })
          }
          const result = await Promise.all(sources.map(source => req(source)))
          set({ loading: false })
        },
        convert: async ({ success, failed }) => {
          set({ loading: true })
          const { knownItems, currentItem } = get()
          const { goodsId, source } = currentItem
          const itemKey = getItemKey(currentItem)
          if (itemKey && knownItems[itemKey]) {
            set({ currentItem: knownItems[itemKey] })
            return
          }
          await axios.post('/convert', { goodsId, source }, { timeout: 10000 })
            .then(async res => {
              var item = res.data
              // url & qr code
              let url
              let deeplinkUrl
              url = item.click_url ? item.click_url : item.url?.match(/(https?:\/\/[^\s]+)/)?.pop()
              url = item.schema_url ? item.schema_url : url
              switch (item.source) {
                case 'taobao':
                  url = item.click_url
                  break
                case 'pdd':
                  url = item.url
                  deeplinkUrl = item.schema_url
                  break
                case 'douyin':
                  url = null
                  deeplinkUrl = item.click_url
                  break
                default:
                  url = item.url
                  deeplinkUrl = item.deeplinkUrl
              }
              res.data.qr = url ? await QRcode.toDataURL(url) : null
              res.data.url = url
              res.data.deeplinkUrl = deeplinkUrl
              res.data.deeplink_qr = deeplinkUrl ? await QRcode.toDataURL(deeplinkUrl) : null
              // final price
              res.data.finalPrice = (item.price - item.commission * 0.85).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })

              const pics = getUniqueValues([
                item.goodsCarouselPictures || [],
                item.goodsDetailPictures || []
              ])

              res.data.pics = pics
              res.data.goodsThumbUrl = pics[0]

              if (typeof (success) === 'function') {
                success()
              }
              set({ currentItem: res.data, knownItems: { ...knownItems, [getItemKey(res.data)]: res.data } })
            })
            .catch(e => {
              if (typeof (failed) === 'function') {
                failed()
              }
            })
          set({ loading: false })
        }
      })
    ),
    { name: 'item-storage' }
  )
)

export default useItemStore