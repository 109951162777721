import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import useMessageStore from '../../stores/messageStore';

export default function Snack() {
  const isOpen = useMessageStore(s => s.isOpen)
  const setOpen = useMessageStore(s => s.setOpen)
  const text = useMessageStore(s => s.text)
  const severity = useMessageStore(s => s.severity)

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'center',
      // }}
      >
        <Alert
          severity={severity}
          action={action}
        >{text}</Alert>
      </Snackbar>
    </div>
  );
}