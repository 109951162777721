import { create } from 'zustand'

const useMessageStore = create((set, get) => ({
  //msg
  isOpen: false,
  text: '',
  severity: 'info',
  setOpen: (value) => set({ isOpen: value }),
  msg: {
    info: (text) => set({ isOpen: true, text, severity: 'info' }),
    success: (text) => set({ isOpen: true, text, severity: 'success' }),
    error: (text) => set({ isOpen: true, text, severity: 'error' }),
  },
  //dialog
  isDialogOpen: false,
  title: '',
  content: '',
  onOk: () => { },
  setDialogOpen: (value) => set({ isDialogOpen: value }),
  dialog: ({ title, content, onOk }) => set({
    isDialogOpen: true,
    title: title || '',
    content: content || '',
    onOk: onOk || function () { }
  })
}))

export default useMessageStore