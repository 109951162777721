import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import FavorateListDrawer from '../components/favorates/Drawer'
import EventList from '../components/events/EventList'
import EventDetailModal from '../components/events/EventDetailModal'
import { Input, Fab, Box, IconButton, Tooltip } from '@mui/material'
import { KeyboardArrowUp, Favorite, Search, Close } from '@mui/icons-material/';
import useEventStore from '../stores/eventStore'
import useFavorateStore from '../stores/favorateStore'
import useMessageStore from '../stores/messageStore'
import LoadingCircle from '../components/LoadingCircle'
import { useTheme } from '@mui/material/styles'

export default function Home() {
  const inputRef = useRef()
  const [keyword, setKeyword] = useState(null)

  const loading = useEventStore(state => state.loading)
  const itemList = useEventStore(s => s.itemList)
  const search = useEventStore(s => s.search)
  const setIsDrawerOpen = useFavorateStore(s => s.setIsDrawerOpen)
  const msg = useMessageStore(s => s.msg)

  const theme = useTheme()

  useEffect(() => {
    search({
      cb: () => msg.error('数据读取失败')
    })
  }, [])

  function handleSearch() {
    setKeyword(inputRef.current?.value || '')
    if (_.isEmpty(keyword)) {
      search({
        keyword: inputRef.current?.value || '.*',
        cb: () => msg.error('数据读取失败')
      })
    }
    search({
      keyword: inputRef.current?.value,
      cb: () => msg.error('数据读取失败')
    })
  }

  function SearchForm() {
    return (
      <Box sx={{ m: 1 }}>
        <Input
          inputRef={inputRef}
          defaultValue={keyword}
          fullWidth
          placeholder='活动名称（正则表达式）'
          onKeyDown={(e) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
              handleSearch()
            }
          }}
          endAdornment={<>
            <IconButton
              size='small'
              onClick={() => {
                inputRef.current.value = ''
                setKeyword('')
                handleSearch()
              }}
              sx={{ visibility: keyword?.length ? "visible" : "hidden" }}
            ><Close></Close></IconButton>
            <IconButton
              size='small'
              onClick={() => {
                handleSearch()
              }}
            ><Search></Search></IconButton>
          </>
          }
        ></Input>
      </Box>
    )
  }

  function FloatingActionButtons() {
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );

      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
    return (
      <Box sx={{
        '& > :not(style)': { m: 1 },
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.fab
      }}>
        <Tooltip title='收藏列表' placement='left'>
          <Fab color="secondary"
            onClick={() => setIsDrawerOpen(true)}
          >
            <Favorite></Favorite>
          </Fab>
        </Tooltip>
        <Tooltip title='回到顶部' placement='left'>
          <Fab aria-label="like" onClick={handleClick}>
            <KeyboardArrowUp />
          </Fab>
        </Tooltip>
      </Box>
    );
  }
  return (
    <>
      <LoadingCircle loading={loading}></LoadingCircle>
      <SearchForm></SearchForm>
      <FloatingActionButtons></FloatingActionButtons>
      <EventList dataSource={itemList}></EventList>
      <EventDetailModal></EventDetailModal>
      <FavorateListDrawer page='events'></FavorateListDrawer>
    </>
  )
}