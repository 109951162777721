import {
  Dialog, DialogTitle, DialogActions, DialogContent,
  List, ListItem, ListItemText, ListItemButton,
  IconButton, Button, Typography, Tooltip
} from "@mui/material";
import { Delete, Clear } from "@mui/icons-material";
import useItemStore from "../../stores/itemStore";

export default function KeywordHistoryDialog() {
  const open = useItemStore(state => state.isHistoryDialogOpen)
  const setOpen = useItemStore(state => state.setHistoryDialogOpen)
  const dataSource = useItemStore(state => state.keywordHistory)
  const remove = useItemStore(state => state.removeKeywordHistory)
  const clear = useItemStore(state => state.clearKeywordHistory)
  const search = useItemStore(state => state.search)
  const setKeyword = useItemStore(state => state.setKeyword)

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemove = (value) => {
    remove(value)
  }

  const handleClear = () => {
    clear()
  }

  const handleSearch = (keyword) => {
    setKeyword(keyword)
    search()
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        搜索历史
        <Tooltip title='清空搜索历史' placement="top">
          <IconButton onClick={handleClear}><Delete></Delete></IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <List
          sx={{ minWidth: 250, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        >
          {dataSource.map(x => (
            <ListItem
              key={x}
              disablePadding
              secondaryAction={
                <Tooltip title='删除条目' placement="top">
                  <IconButton edge="end" aria-label="delete"
                    onClick={() => handleRemove(x)}
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton
                onClick={() => handleSearch(x)}
              >
                <ListItemText
                  primary={
                    <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{x}</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog >
  );
}