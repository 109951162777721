import { CircularProgress } from "@mui/material"

export default function LoadingCircle({ loading }) {
  if (loading) {
    return (
      <CircularProgress
        sx={{ position: 'fixed', bottom: 2, left: 2 }}
      ></CircularProgress>
    )
  }
  return null
}