import FavorateButton from "../favorates/Button";
import { platformEnum } from "../../config";
import { Box, Typography, Button, Grid, Dialog, DialogContent, DialogActions, } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { LinkOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import useItemStore from "../../stores/itemStore";
import { getItemKey } from "../util";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function ItemDetailModal() {
  const currentItem = useItemStore(s => s.currentItem)
  const setIsModalOpen = useItemStore(s => s.setIsModalOpen)
  const isModalOpen = useItemStore(s => s.isModalOpen)
  const convert = useItemStore(s => s.convert)

  useEffect(() => {
    if (isModalOpen) {
      reload()
    }
  }, [getItemKey(currentItem), isModalOpen])

  const reload = () => {
    if (currentItem.goodsId) {
      convert({
        failed: () => { }
      })
    }
  }

  function LinkButton({ text, url, qr }) {
    const image = qr ? <img src={qr} alt={url}
      style={{ maxHeight: 150, maxWidth: 150 }}
    ></img> : null
    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <a target='_blank' rel="noreferrer" href={url}>
          <Button style={{ width: 100 }}>
            <LinkOutlined /> {text}
          </Button>
        </a>
      </div>
      <div>
        {image}
      </div>
    </Box>
    )
  }
  function Links() {
    const link = currentItem.url ?
      <LinkButton text='打开链接' url={currentItem.url} qr={currentItem.qr} /> :
      null
    const deepLink = currentItem.deeplinkUrl ?
      <LinkButton text='打开APP' url={currentItem.deeplinkUrl} qr={currentItem.deeplink_qr} /> :
      null
    return (
      <Box display='flex'>
        {link}
        {deepLink}
      </Box>
    )
  }

  function handleClose() {
    setIsModalOpen(false)
  }
  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        maxWidth='lg'
      >
        <DialogContent sx={{ pb: 0 }}>
          <Grid container
            columns={{ xs: 6, sm: 6, md: 12 }}
          >
            <Grid item xs={6}>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {
                  currentItem?.pics?.map((x, i) => <SwiperSlide key={i}><div
                    key={x}
                    style={{
                      background: '#364d79',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <img
                      style={{ maxHeight: '50vh', maxWidth: '75vw' }}
                      src={x} alt={x} />
                  </div></SwiperSlide>)
                }
              </Swiper>
            </Grid>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={6} md={5}>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  { }
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                  <img src={platformEnum[currentItem.source]?.icon} alt={currentItem.source} style={{ width: 16 }}></img>
                  {currentItem.shopName}
                </Typography>
                <Box sx={{ display: 'flex', '& > :not(style)': { mt: 1, pr: 2 } }}>
                  <Typography>
                    市场价: {currentItem.marketPrice}
                  </Typography>
                  <Typography>
                    折扣券: {currentItem.discount}
                  </Typography>
                  <Typography>
                    折后价: {currentItem.price}
                  </Typography>
                </Box>
                <Typography>
                  券信息: {currentItem.discount != 0 ?
                    `￥${currentItem.couponInfo?.fav} (${currentItem.couponInfo?.useBeginTime} - ${currentItem.couponInfo?.useEndTime})`
                    : '无'}
                </Typography>
                <Box sx={{ display: 'flex', '& > :not(style)': { mt: 1, pr: 2 } }}>
                  <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                    预估价格: {currentItem.finalPrice}
                  </Typography>
                  <Typography>
                    佣金: {currentItem.commission} ({currentItem.commissionRate}%)
                  </Typography>
                </Box>
                <Links></Links>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FavorateButton style={{ width: 50 }} item={currentItem}></FavorateButton>
          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}