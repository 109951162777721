import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import useMessageStore from '../../stores/messageStore';

export default function AlertDialog() {
  const isDialogOpen = useMessageStore(s => s.isDialogOpen)
  const title = useMessageStore(s => s.title)
  const content = useMessageStore(s => s.content)
  const onOk = useMessageStore(s => s.onOk)
  const setDialogOpen = useMessageStore(s => s.setDialogOpen)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDialogOpen(false);
  };

  const handleOk = (event, reason) => {
    if (typeof (onOk) === 'function') {
      onOk()
    }
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleOk} autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}