import {
  createHashRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Home from './routes/Home'
import Events from './routes/Events'
import Layout from './components/Layout'

const routes = [
  {
    path: "/",
    element: <Layout contents={<Outlet />} />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "events",
        element: <Events />,
      },
    ]
  },

]

const router = createHashRouter(routes)

function Dom() {
  return (
    <RouterProvider router={router} />
  );
}

export default Dom;
