import EventCard from "./EventCard"
import { useMemo } from "react"
import { Box } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';

export default function ItemList({ dataSource }) {
  const data = useMemo(() => {
    const sorted = dataSource.slice()
    return sorted
  }, [dataSource])
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} columns={{ xs: 3, sm: 6, md: 12 }}>
        {data.map((x, index) => <Grid xs={3} key={index}><EventCard item={x}></EventCard></Grid>)}
      </Grid>
    </Box>
  )
}