export const getItemKey = (item) => {
  var key
  if (item?.source) {
    switch (item.source) {
      case 'taobao':
        key = `${item.goodsName}@${item.shopName}`
        break
      default:
        key = item.goodsId
    }
  } else if (item?.act_id) {
    key = item?.act_id?.toString()
  }
  return key
}