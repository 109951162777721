import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { getItemKey } from '../components/util'

const useFavorateStore = create(
  persist(
    (set, get) => ({
      items: {},
      events: {},
      isDrawerOpen: false,
      setIsDrawerOpen: (value) => set({ isDrawerOpen: value }),
      clear: (key) => set(() => {
        if (key === 'items' || key === 'events')
          return ({ [key]: {} })
      }),
      toggle: (arg) => set(() => {
        const { items, events } = get()
        const itemKey = getItemKey(arg)
        function toggleFavorate(value) {
          const map = new Map(Object.entries(value))
          if (map.has(itemKey)) {
            map.delete(itemKey)
          }
          else {
            map.set(itemKey, arg)
          }
          return Object.fromEntries(map)
        }
        if (arg.act_id) {
          const newEvents = toggleFavorate(events)
          return { events: newEvents }
        } else if (arg.goodsId) {
          const newItems = toggleFavorate(items)
          return { items: newItems }
        }
      }),
      copy: async ({ key, callback }) => {
        const states = get()
        const value = states[key]
        if (value) {
          navigator.clipboard.writeText(JSON.stringify(value))
            .then(() => {
              if (typeof (callback) === 'function') {
                callback(Object.values(value).filter(x => x).length)
              }
            })
        }
        console.log({ value })
        return {}
      },
      paste: async ({ key, callback }) => {
        const states = get()
        const value = states[key]
        if (value) {
          const newItems = await navigator.clipboard.readText().then(text =>
            new Map(Object.entries(JSON.parse(text)))
          )
          const map = new Map(Object.entries(value))
          newItems.forEach((value, key) => {
            if (!map.has(key)) {
              map.set(key, value)
            }
          })
          const newValue = Object.fromEntries(map)

          if (callback) {
            callback(newItems.size)
          }
          set({ [key]: newValue })
        }
      }
    }),
    {
      name: 'favorate-storage', // unique name
    }
  )
)

export default useFavorateStore