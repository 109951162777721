import * as React from 'react';
import { Tooltip, Box, IconButton, Dialog, Slide, AppBar, Toolbar, Typography, Container } from '@mui/material'
import ItemList from '../items/ItemList'
import EventList from '../events/EventList'
import { Delete, CopyAll, ContentPaste, Close } from '@mui/icons-material'
import useFavorateStore from '../../stores/favorateStore';
import useMessageStore from '../../stores/messageStore';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FavorateListDrawer({ page }) {
  const handleClose = () => {
    setIsDrawerOpen(false)
  }

  const msg = useMessageStore(state => state.msg)
  const dialog = useMessageStore(state => state.dialog)

  const items = useFavorateStore(state => state.items)
  const events = useFavorateStore(state => state.events)
  const isDrawerOpen = useFavorateStore(state => state.isDrawerOpen)
  const copy = useFavorateStore(state => state.copy)
  const paste = useFavorateStore(state => state.paste)
  const clear = useFavorateStore(state => state.clear)
  const setIsDrawerOpen = useFavorateStore(state => state.setIsDrawerOpen)

  return (
    <>
      <Dialog
        fullScreen
        open={isDrawerOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              收藏列表
            </Typography>
            <Box sx={{ pr: 3, '& > :not(style)': { color: 'white' } }}>
              <Tooltip
                key='copy'
                title='复制到剪贴板'>
                <IconButton
                  onClick={async () => {
                    try {
                      copy({
                        key: page,
                        callback: (num) => {
                          msg.success(`${num}个商品已复制到剪贴板`);
                        }
                      })
                    } catch (error) {
                      msg.errer('复制失败')
                      console.log(error)
                    }
                  }}
                ><CopyAll />
                </IconButton>
              </Tooltip>
              <Tooltip
                key='paste'
                title='从剪贴板导入'>
                <IconButton
                  onClick={async () => {
                    try {
                      paste({
                        key: page,
                        callback: (num) => {
                          msg.success(`${num}个商品已导入到收藏列表`);
                        }
                      })
                    } catch (error) {
                      msg.errer('导入失败')
                    }
                  }}
                ><ContentPaste />
                </IconButton>
              </Tooltip>
              <Tooltip
                key='clear'
                title='清空收藏列表'>
                <IconButton
                  onClick={() => {
                    dialog({
                      title: `是否确认清空收藏列表？`,
                      onOk: () => clear(page)
                    })
                  }}
                ><Delete /></IconButton>
              </Tooltip>
            </Box>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container maxWidth='lg'>
          {page === 'events'
            ? <EventList dataSource={Object.values(events || {})}></EventList>
            : <ItemList dataSource={Object.values(items || {})} ></ItemList>}
        </Container>
      </Dialog >
    </>
  )
}