import * as React from 'react';
import { useRef } from 'react'
import _ from 'lodash'
import FavorateListDrawer from '../components/favorates/Drawer'
import ItemDetailModal from '../components/items/ItemDetailModal'
import ItemList from '../components/items/ItemList'
import {
  Input, Fab, Box, IconButton, Grid,
  Dialog, DialogTitle, DialogActions, DialogContent,
  Button, Tooltip
} from '@mui/material'
import { KeyboardArrowUp, Favorite, ContentPaste, Search, Close, Settings, History } from '@mui/icons-material/';
import { useTheme } from '@mui/material/styles';
import useItemStore from '../stores/itemStore'
import useFavorateStore from '../stores/favorateStore'
import { PageSizeRadioGroup, SortSelect, SourceSelect, Filters } from '../components/items/Settings';
import LoadingCircle from '../components/LoadingCircle';
import KeywordHistoryDialog from '../components/items/KeywordHistoryDialog';

export default function Home() {
  const loading = useItemStore(state => state.loading)
  const dataSource = useItemStore(state => state.itemList)
  const setKeyword = useItemStore(state => state.setKeyword)
  const search = useItemStore(state => state.search)
  const setHistoryDialogOpen = useItemStore(state => state.setHistoryDialogOpen)

  const setIsDrawerOpen = useFavorateStore(state => state.setIsDrawerOpen)

  const theme = useTheme()

  function handleSearch() {
    search()
  }

  function SettingsDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <React.Fragment>
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <Settings></Settings>
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>搜索设置</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', '& > :not(style)': { pb: 1 } }}>
            <SortSelect showLabel></SortSelect>
            <PageSizeRadioGroup showLabel></PageSizeRadioGroup>
            <Filters showLabel></Filters>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSearch} color='primary'>Search</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  function SearchForm() {
    const inputRef = useRef()
    const keyword = useItemStore(state => state.keyword)

    return (
      <Box
        sx={{
          m: 1,
          flexGrow: 1
        }}
      >
        <Grid container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ '& > :not(style)': { mr: 2 }, alignItems: 'center' }}
        >
          <Grid>
            <SettingsDialog></SettingsDialog>
          </Grid>
          <Grid>
            <SourceSelect></SourceSelect>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Input
              sx={{ width: '100%' }}
              inputRef={inputRef}
              defaultValue={keyword}
              placeholder='关键词/链接/淘口令'
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  handleSearch()
                }
              }}
              onChange={(e) => setKeyword(e.target.value)}
              endAdornment={<>
                <IconButton
                  size='small'
                  onClick={() => {
                    inputRef.current.value = ''
                    setKeyword('')
                  }}
                  sx={{ visibility: keyword.length ? "visible" : "hidden" }}
                ><Close></Close></IconButton>
                <IconButton
                  size='small'
                  onClick={() => {
                    handleSearch()
                  }}
                ><Search></Search></IconButton>
              </>
              }
            ></Input>

          </Grid>
        </Grid>
      </Box>
    )
  }

  function FloatingActionButtons() {
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );

      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
    return (
      <Box sx={{
        '& > :not(style)': { m: 1 },
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.fab
      }}>
        <Tooltip title='收藏列表' placement='left'>
          <Fab color="secondary"
            onClick={() => setIsDrawerOpen(true)}
          >
            <Favorite></Favorite>
          </Fab>
        </Tooltip>
        <Tooltip title='搜索历史' placement='left'>
          <Fab color="success"
            onClick={() => setHistoryDialogOpen(true)}
          >
            <History></History>
          </Fab>
        </Tooltip>
        <Tooltip title='搜索剪贴板内容' placement='left'>
          <Fab color="primary" aria-label="edit"
            onClick={() => {
              navigator.clipboard.readText().then(text => {
                setKeyword(text)
                search()
              })
            }}
          >
            <ContentPaste></ContentPaste>
          </Fab>
        </Tooltip>
        <Tooltip title='回到顶部' placement='left'>
          <Fab aria-label="like" onClick={handleClick}>
            <KeyboardArrowUp />
          </Fab>
        </Tooltip>
      </Box>
    );
  }

  return (
    <>
      <LoadingCircle loading={loading}></LoadingCircle>
      <KeywordHistoryDialog></KeywordHistoryDialog>
      <SearchForm></SearchForm>
      <FloatingActionButtons></FloatingActionButtons>
      <ItemList dataSource={dataSource} filtered></ItemList>
      <ItemDetailModal></ItemDetailModal>
      <FavorateListDrawer page='items'></FavorateListDrawer>
    </>
  )
}
