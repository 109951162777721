import { Button, IconButton } from '@mui/material'
import { getItemKey } from '../util'
import { FavoriteBorder, Favorite } from '@mui/icons-material'
import useFavorateStore from '../../stores/favorateStore'

export default function FavorateButton({ item, style, type, fullWidth }) {
  const hasItem = (item) => {
    const itemKey = getItemKey(item)
    return (itemKey && (items[itemKey] || events[itemKey])) ? true : false
  }

  const items = useFavorateStore(s => s.items)
  const events = useFavorateStore(s => s.events)
  const toggle = useFavorateStore(s => s.toggle)

  var flag
  if (item) {
    flag = hasItem(item)
  }
  if (type === 'icon') {
    return <IconButton
      sx={style}
      fullWidth={fullWidth}
      onClick={() => { toggle(item) }}>
      {flag ? <Favorite size={20} sx={{ color: 'red' }}></Favorite> : <FavoriteBorder size={20} sx={{ color: 'red' }}></FavoriteBorder>}
    </IconButton>
  }
  return <Button
    sx={style}
    fullWidth={fullWidth}
    onClick={() => { toggle(item) }}>
    {flag ? <Favorite size={20} sx={{ color: 'red' }}></Favorite> : <FavoriteBorder size={20} sx={{ color: 'red' }}></FavoriteBorder>}
  </Button>
}